import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function useKeycloakToken() {
  const { keycloak, initialized } = useKeycloak();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const intervalIdRef = useRef<any>(null);
  const loginInProgressRef = useRef<boolean>(false);

  useEffect(() => {
    const checkSession = () => {
      if (!initialized) {
        // Keycloak is still loading, do nothing
        return;
      }

      if (!keycloak.authenticated || keycloak.isTokenExpired()) {
        keycloak.onTokenExpired = () => { };
        keycloak.onAuthError = () => { };
        setShowLoginModal(true);
      } else {
        setShowLoginModal(false);
      }
    };

    // Check session immediately
    checkSession();

    // Set up a periodic session check
    intervalIdRef.current = setInterval(checkSession, 1000);

    // Set up the onTokenExpired event handler
    keycloak.onTokenExpired = () => {
      if (!initialized || loginInProgressRef.current) {
        console.error('Keycloak not initialized or login in progress');
        keycloak.logout();
      }
      setShowLoginModal(true); // Show the login modal instead of logging in directly
      // Clear the token handlers to stabilize the state
      keycloak.onTokenExpired = () => { };
      keycloak.onAuthError = () => { };
    };

    // Set up the onAuthError event handler
    keycloak.onAuthError = (error) => {
      if (!initialized || loginInProgressRef.current) {
        console.error('Keycloak not initialized or login in progress');
        return;
      }
      console.error('Authentication error:', error);
      setShowLoginModal(true); // Show the login modal on authentication error
      // Clear the token handlers to stabilize the state
      keycloak.onTokenExpired = () => { };
      keycloak.onAuthError = () => { };
    };

    // Clean up the event handler and interval on unmount
    return () => {
      keycloak.onTokenExpired = () => { }; // Assign an empty function instead of null
      keycloak.onAuthError = () => { }; // Assign an empty function instead of null
      clearInterval(intervalIdRef.current); // Clear the interval
    };
  }, [initialized, keycloak.authenticated, keycloak.token]); // Only run this effect when keycloak changes

  const handleLogin = () => {
    if (keycloak) {
      loginInProgressRef.current = true;
      keycloak.login();
    } else {
      console.error('Keycloak instance is not available');
    }
  };

  return [keycloak.token, showLoginModal, handleLogin];
}

export function LoginModal({ showLoginModal, handleLogin }: any) {
  const { t } = useTranslation();

  if (!showLoginModal) {
    return null;
  }

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto bg-medium-gray bg-opacity-80">
      <div className="opacity-100 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="z-60 opacity-100 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="text-center bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-2xl leading-6 font-medium text-black">
              {t("Session Expired")}
            </h3>
            <div className="mt-2">
              <p className="text-secondary-gray">
                {t("Your session has expired. Please log in again.")}
              </p>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="font-bold w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base text-white hover:bg-status-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleLogin}
              >
                {t("Log In")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}