import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from "react";
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Spinner from "../common/Spinner";
import DestinationDetailsModal from "./DestinationDetailsModal";
import { Connection, ConnectionConfiguration, Destination, Organization, buildDestination } from "../common/ObjectTypes";
import { getConnectionsList, testConnectionAction } from "../../api/connections/connectionsApi";
import { getDestinationDetails, updateDestination } from "../../api/destinations/destinationsApi";
import { toast } from "react-toastify";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import loadOrganizations from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";
import { useParams } from "react-router-dom";
import { useKeycloakToken } from "../common/useKeycloak";
import { useTranslation } from "react-i18next";

export function ManageDestinationDetails() {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { dest_id } = useParams<{ dest_id: string }>();
    const { dest_subpage } = useParams();
    const [destSubPage, setDestSubPage] = useState<string>();
    const navigate = useNavigate();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [destination, setDestination] = useState<Destination>(buildDestination())
    const [destinationTitle, setDestinationTitle] = useState<string>("")
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orderingString, setOrderingString] = useState("");
    const [loadingConnections, setLoadingConnections] = useState(true);
    const [selectedDestConnections, setSelectedDestConnections] = useState<Connection[]>([]);
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [testActionLoading, setTestActionLoading] = useState(false);
    const [testActionData, setTestActionData] = useState(null);
    const [testActionStatus, setTestActionStatus] = useState<number>(0);
    const [errors, setErrors] = useState({});
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const [orgOffset, setOrgOffset] = useState<number>(0);
    const [orgSearchInput, setOrgSearchInput] = useState<string>("");
    const [totalOrgs, setTotalOrgs] = useState<number>(0);
    const [reloadOrgs, setReloadOrgs] = useState<boolean>(true);
    const onToggleModal = () => {
        const pathArray = window.location.pathname.split('/');
        const destinationIndex = pathArray.indexOf('destination');
        if (destinationIndex > -1) {
            // Remove 'destination' and any segments that follow
            const newPathArray = pathArray.slice(0, destinationIndex);
            const newPath = newPathArray.join('/') || '/';
            navigate(newPath);
        }
    }

    useEffect(() => {

        const loadDestinationDetails = async () => {
            if (keycloak.token && dest_id && dest_subpage) {
                const temp = await getDestinationDetails(keycloak.token, dest_id);
                loadSelectedDestinationConnections()
                setDestination(temp);
                setDestinationTitle(temp.name)
                setDestSubPage(dest_subpage);
            }
        }

        const loadSelectedDestinationConnections = async () => {
            if (keycloak.token) {
                try {
                    const connectionFilterQuery = {
                        destination_id: dest_id,
                        ordering: orderingString,
                        offset: offset
                    }
                    const temp_connections = await getConnectionsList(keycloak.token, connectionFilterQuery)
                    setSelectedDestConnections(temp_connections.results);
                    setTotalCount(temp_connections.count);
                    setLoadingConnections(false);
                    setPageLoaded(true);
                } catch (err) {
                    console.log(err)
                }
            }
        }
        const loadOrganizationsData = async () => {
            if (keycloak.token) {
                const [orgs, total_orgs, error] = await loadOrganizations(keycloak.token, orgOffset, orgSearchInput, organizations);
                if (!error) {
                    setOrganizations(orgs);
                    if (orgSearchInput.length === 0) setTotalOrgs(total_orgs);
                    setReloadOrgs(false);
                }
            }
        }
        loadDestinationDetails();
        if (reloadOrgs) loadOrganizationsData();
    }, [loadingConnections, reloadOrgs, dest_id, keycloak.token])

    const handlePageClick = (e: any) => {
        setOffset(e.selected * page_size);
        setLoadingConnections(true);
    }

    const requestSortSelectedDestinationConnection = (orderBy: string, sortDirection: boolean) => {
        const ordering = sortDirection ? orderBy : "-" + orderBy;
        setOrderingString(ordering);
        setLoadingConnections(true);
    }

    const onChangeSelectedDestination = (e: any) => {
        const attr: string = e.target.id
        setDestination((prevDestination: Destination) => ({
            ...prevDestination,
            [attr]: e.target.value
        }))
    }

    const onChangeSelectedDestinationOwner = (e: any) => {
        setDestination((prevDestination: Destination) => ({
            ...prevDestination,
            "owner": {
                name: e.label,
                id: e.value,
                description: ""
            },
        }))
    }

    const onChangeSelectedDestinationConfig = (e: ChangeEvent<HTMLInputElement>, action_id: string) => {
        const config_id: any = destination.configurations && destination.configurations.find((a: any) => a.action.id === action_id)
        let newConfig: any;
        if (config_id) {
            newConfig = {
                "action_id": action_id,
                "id": config_id.id,
                "data": e
            }
        } else {
            newConfig = {
                "action_id": action_id,
                "data": e,
                "id": action_id,
            }
        }
        if (e) {
            const index_to_find = updatedConfigurations.findIndex((a: ConnectionConfiguration) => (config_id && a.id === config_id.id) || a.action_id === action_id)
            if (index_to_find === -1) {

                // if this is a new config id, push it to the array
                setUpdatedConfigurations((prevArray: any[]) => [...prevArray, newConfig])
            } else {

                // if it is an existing config id, replace the old config with the new one
                const tempArray = updatedConfigurations
                tempArray[index_to_find] = newConfig
                setUpdatedConfigurations(tempArray)
            }

        }
    }

    const handleTestAction = async (action_id: string, action_type: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            setTestActionLoading(true);
            setTestActionStatus(0);
            // configuration form data for the relevant action
            let form_data = {}
            // find the index in updatedConfigurations that matches the action_id
            let updated_config_index = updatedConfigurations.findIndex((a: any) => a.action_id === action_id)
            let config_overrides = {}
            // save the data from the updatedConfigurations array if it exists
            if (updated_config_index !== -1) {
                form_data = updatedConfigurations[updated_config_index].data
            } else {
                form_data = destination.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === action_id) !== -1 ? destination.configurations.find((a: ConnectionConfiguration) => a.action.id === action_id).data : {}
            }
            let result = await testConnectionAction(token, destination.id, action_type, { config_overrides: Object(form_data) });

            if (result) {
                let { data: resp_data, status } = result;
                setTestActionData(resp_data);
                setTestActionStatus(status);
            }
            setTestActionLoading(false);
        }
    }

    const onToggleDestinationEnabled = () => {
        setDestination((prevDest: Destination) => ({
            ...prevDest,
            enabled: !destination.enabled
        }))
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        if (destination.base_url.length === 0) {
            form_errors.base_url = "URL is required";
        }
        if (destination.base_url.length > 0) {
            try {
                new URL(destination.base_url);
            } catch (err) {
                form_errors.base_url = "Enter a valid url including the scheme.";
            }
        }
        if (destination.name.length === 0) {
            form_errors.name = "Name is required";
        }
        setErrors(form_errors);

        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = destination.type.actions.findIndex((a: any) => a.id === config.action_id);
            const ajv = new Ajv({ allErrors: true, verbose: true });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            ajv.addKeyword({
                keyword: 'example',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);
            const schema = destination.type.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null; // or any default value you prefer
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }

            const valid = validate(data);
            if (!valid) {
                validate.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1);
                    if (!rsjf_errors[destination.type.actions[schema_index].id]) {
                        rsjf_errors[destination.type.actions[schema_index].id] = {};
                    }
                    rsjf_errors[destination.type.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });

        setRsjfErrors(rsjf_errors);
        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }
    // rsjf_errors[destination.type.actions[schema_index].id]
    const handleSaveDestination = async () => {
        if (!formIsValid()) return;
        else {
            if (keycloak.token) {
                await updateDestination(keycloak.token, destination.id, {
                    "base_url": destination.base_url,
                    "configurations": updatedConfigurations,
                    "enabled": destination.enabled,
                    "owner": destination.owner.id,
                    "name": destination.name
                });
                toast.success(`${t('Destination updated')}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                onToggleModal();
            }
        }
    }

    function handleSearchOrgDropdown(e: any) {
        setOrgSearchInput(e);
        if (e.length > 2) setReloadOrgs(true);
    }

    function handleLoadMoreOrgs() {
        setOrgOffset(orgOffset + page_size)
        if (organizations.length < totalOrgs) setReloadOrgs(true);
    }

    function handleSelectSubPage(e: string) {
        setDestSubPage(e)
        const pathArray = window.location.pathname.split('/');
        // Replace the last segment with `e`
        pathArray[pathArray.length - 1] = e;
        const newPath = pathArray.join('/');
        navigate(newPath);
    }

    return (
        <DestinationDetailsModal
            destination={destination}
            destinationTitle={destinationTitle}
            destSubPage={destSubPage}
            onSelectSubPage={handleSelectSubPage}
            organizations={organizations}
            loadMoreOrgs={handleLoadMoreOrgs}
            orgInputChange={handleSearchOrgDropdown}
            connections={selectedDestConnections}
            onToggleModal={onToggleModal}
            requestSort={requestSortSelectedDestinationConnection}
            loadingConnections={loadingConnections}
            onChangeSelectedDestination={onChangeSelectedDestination}
            onChangeSelectedDestinationOwner={onChangeSelectedDestinationOwner}
            onChangeDestinationEnabled={onToggleDestinationEnabled}
            onChangeSelectedDestinationConfig={onChangeSelectedDestinationConfig}
            onTestAction={handleTestAction}
            testActionLoading={testActionLoading}
            testActionResponse={testActionData}
            testActionStatus={testActionStatus}
            onSave={handleSaveDestination}
            errors={errors}
            rsjfErrors={rsjfErrors}
            onPageClicked={handlePageClick}
            totalCount={totalCount}
            pageLoaded={pageLoaded}
            take={page_size}
            offset={offset}
        />
    )
}