import React, { useState, ChangeEventHandler } from "react";
import { DestinationType, Organization } from "../common/ObjectTypes";
import GlobalSearch from "../common/GlobalSearch";
import SelectList from "../common/SelectList";
import { ArrowDown, ArrowUp, Clear, FilterIcon } from "../common/ProjectIcons";
import { useTranslation } from 'react-i18next';

type DestinationFilterProps = {
    destinationUrls: string[],
    destinationTypes: DestinationType[],
    organizations: Organization[],
    filters: any[],
    globalSearchStr?: string,
    onChangeGlobalSearch: Function,
    onRemoveTag: any,
    onResetFilters: any,
    onChangeEndpoint?: ChangeEventHandler,
    onChangeState?: ChangeEventHandler,
    onChangeType: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onLoadMoreDestinationTypes: Function,
    onChangeOwner: ChangeEventHandler,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeOwnerSearchBar: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    showStatus: boolean,
    showUrlFilter: boolean,
    loadingDestinationUrls: boolean
};

const DestinationFilter = ({
    destinationUrls,
    destinationTypes,
    organizations,
    filters,
    globalSearchStr,
    onChangeGlobalSearch,
    onRemoveTag,
    onResetFilters,
    onChangeEndpoint,
    onChangeState,
    onChangeType,
    onChangeDestinationTypeSearchBar,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onLoadMoreDestinationTypes,
    onChangeOwner,
    loadingOrganizations,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeOwnerSearchBar,
    onChangeDestinationUrlSearchBar,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    showStatus,
    showUrlFilter,
    loadingDestinationUrls
}: DestinationFilterProps) => {
    const { t } = useTranslation();
    const [stateFilterExpanded, setStateFilterExpanded] = useState(true);
    const toggleStateFilter = () => setStateFilterExpanded(!stateFilterExpanded)
    return (
        // FILTERS
        <div className="text-off-black bg-very-light-gray mr-3 mb-3 h-auto pb-3 px-3 divide-y divide-gray-lines max-h-screen overflow-y-scroll overflow-x-hidden">
            <GlobalSearch
                onChangeGlobalSearch={onChangeGlobalSearch}
                savedString={globalSearchStr}
            />
            <div className="flex flex-row justify-between mt-2 pt-2">
                <h3><FilterIcon /> {t("Filters")}</h3>
                {filters.length > 0 && <h3 className="bg-dark-green text-white text-sm py-1 px-3 rounded-full">{filters.length}</h3>}
            </div>
            {/* tags of all of the filters */}
            {filters.length > 0 &&
                <div className="flex flex-col mt-3 pt-3">
                    {filters.map((item: any) => {
                        return (
                            <p className="text-sm w-fit py-1 px-2 bg-light-green mt-1 rounded-sm items-end"
                                key={item.id}
                            >
                                {t(item.name)}
                                <span className="pb-3 text-dark-green cursor-pointer"
                                    onClick={() => onRemoveTag(item.id)}>
                                    <Clear />
                                </span>
                            </p>
                        )
                    })}
                    <button className="w-fit bg-very-light-gray text-dark-green mt-3"
                        onClick={onResetFilters}
                    >{t("Clear All")}</button>
                </div>}
            {/* destination url */}
            {showUrlFilter && <div className="my-6 pt-3">
                <SelectList
                    optionList={destinationUrls.map((url) => {
                        let new_url: string | URL;
                        try {
                            new_url = new URL(url)
                        } catch (err) {
                            new_url = ""
                        }
                        return {
                            name: new_url instanceof URL ? new_url.hostname : "",
                            id: url,
                            attribute_name: "base_url__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeEndpoint}
                    onChangeSearchBar={onChangeDestinationUrlSearchBar}
                    sectionLabel={t("Filter by Destination")}
                    loadMore={loadMoreDestinationUrls}
                    onClickLoadMore={() => onLoadMoreDestinationUrls()}
                    loading={loadingDestinationUrls}
                />
            </div>}
            {/* status */}
            {showStatus && <div className="my-6 pt-3">
                <div className="w-full flex flex-row justify-between" onClick={toggleStateFilter}>
                    <label className="font-semibold">{t("Filter by State")}</label>{stateFilterExpanded ? <ArrowUp /> : <ArrowDown />}
                </div>
                {stateFilterExpanded &&
                    <div>
                        <div className="mt-1">
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("true")}
                                type="checkbox"
                                value="active"
                                id="enabled"
                                onChange={onChangeState} />
                            <label
                                className="inline-block mx-2"
                            >
                                {t("Active")}
                            </label>
                        </div>
                        <div>
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("false")}
                                type="checkbox"
                                value="inactive"
                                id="enabled"
                                onChange={onChangeState} />
                            <label
                                className="inline-block mx-2"
                            >
                                {t("Inactive")}
                            </label>
                        </div>
                    </div>
                }
            </div>}

            {/* type (uuid of related type) */}
            <div className="my-6 pt-3">
                <SelectList
                    optionList={destinationTypes.map((dest) => {
                        return {
                            name: dest.name,
                            id: dest.id,
                            attribute_name: "type__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeType}
                    sectionLabel={t("Filter by Type")}
                    onChangeSearchBar={onChangeDestinationTypeSearchBar}
                    loadMore={loadMoreDestinationTypes}
                    onClickLoadMore={() => onLoadMoreDestinationTypes()}
                    loading={loadingDestinationTypes}
                />
            </div>
            {/* owner (uuid of related organization) */}
            <div className="flex flex-col my-6 pt-3">
                <SelectList
                    optionList={organizations.map((org) => {
                        return {
                            name: org.name,
                            id: org.id,
                            attribute_name: "owner__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeOwner}
                    sectionLabel={t("Filter by Organization")}
                    loadMore={loadMoreOrganizations}
                    onChangeSearchBar={onChangeOwnerSearchBar}
                    onClickLoadMore={() => onLoadMoreOrganizations()}
                    loading={loadingOrganizations}
                />
            </div>
        </div>
    )
}

export default DestinationFilter;