import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { Destination, DestinationType, Organization } from "../common/ObjectTypes";
import DestinationFilter from "../destinations/DestinationFilter";
import { useTranslation } from 'react-i18next';
import { Delete, SortingArrows } from "../common/ProjectIcons";
import ConnectionDestinationTable from "../destinations/ConnectionDestinationTable";

type StepThreeProps = {
    destinations: Destination[],
    existingDestPhase0: Destination[],
    existingDestPhase1: Destination[],
    existingDestPhase2: Destination[],
    existingDestPhase3: Destination[],
    onSelectExistingDestination: Function,
    destinationTypes: DestinationType[],
    organizations: Organization[],
    filters: any[],
    onChangeStateFilter: ChangeEventHandler,
    onChangeTypeFilter: ChangeEventHandler,
    onChangeOwnerFilter: ChangeEventHandler,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadMoreOrganizations: boolean,
    loadingOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onGlobalDestinationFilter: Function,
    onRemoveDestinationFilterTag: any,
    onResetDestinationFilters: any,
    pageLoaded: boolean,
    addExistingDest: boolean,
    updatingDestinations: boolean,
    onClickAddDestination: Function,
    onAddExistingDestinations: MouseEventHandler,
    onViewDetails: Function
}

const NewConnectionStepThree = ({
    destinations,
    destinationTypes,
    organizations,
    existingDestPhase0,
    existingDestPhase1,
    existingDestPhase2,
    existingDestPhase3,
    onSelectExistingDestination,
    filters,
    onChangeStateFilter,
    onChangeTypeFilter,
    onChangeOwnerFilter,
    onChangeOwnerSearchBar,
    loadMoreOrganizations,
    loadingOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationUrlSearchBar,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onGlobalDestinationFilter,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters,
    updatingDestinations,
    addExistingDest,
    onClickAddDestination,
    onAddExistingDestinations,
    onViewDetails
}: StepThreeProps) => {
    const { t } = useTranslation();
    return (
        <div className="mt-8">
            {/* text, list of destinations, filter panel on the left */}
            <div className="flex flex-col w-full">
                <label className="text-off-black text-lg">{t("Select the destination where you'd like to see this data.")}</label>
                {/* filter panel and tables of destinations */}
                <div className="flex flex-row mt-8 mb-16">
                    {/* filter panel */}
                    {addExistingDest && <DestinationFilter
                        destinationUrls={[]}
                        destinationTypes={destinationTypes}
                        organizations={organizations}
                        filters={filters}
                        onChangeGlobalSearch={onGlobalDestinationFilter}
                        onRemoveTag={onRemoveDestinationFilterTag}
                        onResetFilters={onResetDestinationFilters}
                        onChangeState={onChangeStateFilter}
                        onChangeType={onChangeTypeFilter}
                        onChangeOwner={onChangeOwnerFilter}
                        onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                        loadMoreOrganizations={loadMoreOrganizations}
                        loadingOrganizations={loadingOrganizations}
                        onLoadMoreOrganizations={onLoadMoreOrganizations}
                        onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                        onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                        loadingDestinationTypes={loadingDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                        showStatus={true}
                        showUrlFilter={false}
                        loadingDestinationUrls={false}
                        loadMoreDestinationUrls={false}
                        onLoadMoreDestinationUrls={() => { }}
                    />}
                    <div className="flex flex-col w-full">
                        <ConnectionDestinationTable
                            destinations={destinations}
                            existingDestPhase0={existingDestPhase0}
                            existingDestPhase1={existingDestPhase1}
                            existingDestPhase2={existingDestPhase2}
                            existingDestPhase3={existingDestPhase3}
                            addExistingDestinations={addExistingDest}
                            onAddExistingDestinations={onAddExistingDestinations}
                            updatingDestinations={updatingDestinations}
                            onClickAddDestination={onClickAddDestination}
                            onViewDetails={onViewDetails}
                            onRemoveDestination={() => { }}
                            onSelectDestination={onSelectExistingDestination}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewConnectionStepThree;