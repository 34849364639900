import React, { MouseEventHandler } from "react";
import { Connection, Provider } from "../common/ObjectTypes";
import { useTranslation } from "react-i18next";
import { getConnectionStatus } from "./getConnectionStatus";
import { Switch } from "@headlessui/react";

type ConnectionDetailsProps = {
    reloadConnection: boolean,
    connection: Connection,
    connectionProvider: Provider,
    subPage: string | undefined,
    onSelectSubPage: Function,
    onChangeProviderEnabled: Function,
}

const ConnectionDetails = ({
    reloadConnection,
    connection,
    connectionProvider,
    subPage,
    onSelectSubPage,
    onChangeProviderEnabled
}: ConnectionDetailsProps) => {
    const { t } = useTranslation();
    const connectionStatus = getConnectionStatus(connection.status, true, t);

    return (
        <div className="relative h-full">
            <div className="bg-white shadow static inset-x-0 px-12 pt-5  -mt-5 -mx-5 xl:-mx-12">
                {/* page header */}
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col w-3/4">
                        <div className="flex flex-row items-center"><h1 className="text-3xl font-semibold">{connection.provider.name}</h1> {(!reloadConnection && connectionProvider.enabled) && <p className={`mx-3 ${connectionStatus.style}`}>{connectionStatus.text}</p>}</div>
                        <label className="text-secondary-gray mt-2 mb-3">{t("Organization")}: {connection.owner.name}</label>
                    </div>
                    <div className={`flex flex-row items-center p-4 gap-4 w-1/4 ${connectionProvider.enabled ? 'bg-light-green' : 'bg-light-gray-button'}`}>
                        <p className="text-left text-sm">
                            {connectionProvider.enabled
                                ? <span><span className="font-semibold">{t("Enabled")}</span> - {t("The connection will send data to its destinations.")}</span>
                                : <span><span className="font-semibold">{t("Disabled")}</span> - {t("The connection will not send data to its destinations.")}</span>}
                        </p>
                        <div className="w-16">
                            <Switch
                                checked={connectionProvider.enabled}
                                onChange={(e) => onChangeProviderEnabled(e)}
                                className="group relative flex h-7 w-14 cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white bg-secondary-gray data-[checked]:bg-status-green"
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block size-5 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
                                />
                            </Switch>
                        </div>

                    </div>
                </div>
                {/* tabs for sub-pages */}
                <div className="w-full flex flex-row font-semibold text-medium-gray">
                    <label className={(subPage === "overview") ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("overview")
                        }}>
                        {t("Overview")}
                    </label>
                    <label className={(subPage === "provider") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("provider")
                        }}>
                        {t("Provider")}
                    </label>
                    <label className={(subPage === "destinations") ? "border-2 border-white border-b-status-green text-dark-gray py-3 mx-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("destinations")
                        }}>
                        {t("Destinations")}
                    </label>
                    <label className={(subPage === "sources") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("sources")
                        }}>
                        {t("Sources")}
                    </label>
                    <label className={(subPage === "logs") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("logs")
                        }}>
                        {t("Logs")}
                    </label>

                </div>
            </div>
        </div>
    );
};

export default ConnectionDetails;