import React, { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { Connection, getConnectionHealthStatuses, Destination, DestinationType, Organization, ProviderType } from "../common/ObjectTypes";
import Pagination from "../common/Pagination";
import GlobalSearch from "../common/GlobalSearch";
import { Clear, FilterIcon, HealthyIcon, SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import SelectList from "../common/SelectList";
import { AttentionIcon } from "../common/ProjectIcons";
import NoResults from "../common/NoResults";
import Spinner from "../common/Spinner";
import { page_size } from "../../api/apiUtils";
import { Tooltip } from "../common/Tooltip";
import RadioSelectList from "../common/RadioSelectList";
import { getConnectionStatus } from "./getConnectionStatus";

type ConnectionsProps = {
    canAddConnection: boolean,
    connections: Connection[],
    reloadConnections: boolean,
    organizations: Organization[],
    destinationUrls: string[],
    destinationTypes: DestinationType[],
    providerTypes: ProviderType[],
    onSelectConnection: Function,
    onChangeGlobalSearch: Function,
    onChangeStatusFilter: MouseEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeDestinationUrl: ChangeEventHandler,
    onLoadMoreDestinationUrls: Function,
    loadingDestinationUrls: boolean,
    loadMoreDestinationUrls: boolean,
    onChangeDestinationType: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onChangeProviderTypeSearchBar: ChangeEventHandler,
    onChangeProviderType: ChangeEventHandler,
    loadingProviderTypes: boolean,
    loadMoreProviderTypes: boolean,
    onLoadMoreProviderTypes: Function,
    onChangeOwner: ChangeEventHandler,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onRemoveTag: any,
    onResetFilters: any,
    filters: any[],
    globalSearchStr: string,
    requestSort: Function,
    onViewDetails: Function,
    onPageClicked: Function,
    totalCount: number,
    pageLoaded: boolean,
    take: number,
    offset: number,
    onClickAdd: MouseEventHandler
}

const ConnectionsList = ({
    canAddConnection,
    connections,
    reloadConnections,
    organizations,
    destinationTypes,
    destinationUrls,
    providerTypes,
    onSelectConnection,
    onChangeGlobalSearch,
    onChangeStatusFilter,
    onChangeDestinationUrlSearchBar,
    onChangeDestinationUrl,
    onLoadMoreDestinationUrls,
    loadingDestinationUrls,
    loadMoreDestinationUrls,
    onChangeDestinationType,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onChangeProviderTypeSearchBar,
    onChangeProviderType,
    loadingProviderTypes,
    loadMoreProviderTypes,
    onLoadMoreProviderTypes,
    onChangeOwner,
    onChangeOwnerSearchBar,
    loadMoreOrganizations,
    loadingOrganizations,
    onLoadMoreOrganizations,
    onRemoveTag,
    onResetFilters,
    filters,
    globalSearchStr,
    onViewDetails,
    requestSort,
    onPageClicked,
    totalCount,
    pageLoaded,
    take,
    offset,
    onClickAdd
}: ConnectionsProps) => {
    const { t } = useTranslation();
    const [sortDirection, setSortDirection] = useState(true);
    const [sortByColumn, setSortByColumn] = useState("name");

    const connectionHealthStatuses = getConnectionHealthStatuses(t);

    return (
        <div className="bg-very-light-gray">
            <div className="mb-3 flex justify-between">
                <div>
                    <h1 data-testid="connections_page_header" className="text-3xl font-bold mb-3">
                        {t("Connections")}
                    </h1>
                </div>
                {canAddConnection && <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7"
                    onClick={onClickAdd}
                >
                    {t("Create Connection")}
                </button>}
            </div>
            <div className="flex flex-row items-start w-full">
                <div className="text-off-black bg-very-light-gray mr-3 mb-3 h-auto px-3 pb-3 divide-y divide-gray-lines max-h-screen overflow-y-scroll overflow-x-hidden">
                    <GlobalSearch
                        onChangeGlobalSearch={onChangeGlobalSearch}
                        savedString={globalSearchStr}
                    />
                    <div className="flex flex-row justify-between mt-2 pt-2">
                        <h3><FilterIcon /> {t("Filters")}</h3>
                        {filters.length > 0 && <h3 className="bg-dark-green text-white text-sm py-1 px-3 rounded-full">{filters.length}</h3>}
                    </div>
                    {filters.length > 0 &&
                        <div className="flex flex-col mt-3 pt-3">
                            {filters.map((item: any) => {
                                return (
                                    <p className="text-sm w-fit py-1 px-2 bg-light-green mt-1 rounded-sm items-end"
                                        key={item.id}
                                    >
                                        {t(item.name)}
                                        <span className="pb-3 text-dark-green cursor-pointer"
                                            onClick={() => onRemoveTag(item.id)}>
                                            <Clear />
                                        </span>
                                    </p>
                                )
                            })}
                            <button className="w-fit bg-very-light-gray text-dark-green mt-3"
                                onClick={onResetFilters}
                            >{t("Clear All")}</button>
                        </div>}
                    {/* status */}
                    <div className="my-6 pt-3">
                        <RadioSelectList
                            optionList={connectionHealthStatuses.map((status) => {
                                return {
                                    name: status.name,
                                    id: status.value,
                                    attribute_name: "status"
                                }
                            })}
                            selectedList={filters}
                            onChangeFilter={onChangeStatusFilter}
                            sectionLabel={t("Filter by Status")}
                            loadMore={false}
                            loading={false}
                        />
                    </div>
                    {/* provider type */}
                    <div className="my-6 pt-3">
                        <SelectList
                            optionList={providerTypes.map((provider) => {
                                return {
                                    name: provider.name,
                                    id: provider.value,
                                    attribute_name: "provider_type__in"
                                }
                            })}
                            selectedList={filters}
                            onChangeSearchBar={onChangeProviderTypeSearchBar}
                            onChangeFilter={onChangeProviderType}
                            sectionLabel={t("Filter by Provider Type")}
                            loadMore={loadMoreProviderTypes}
                            loading={loadingProviderTypes}
                            onClickLoadMore={() => onLoadMoreProviderTypes()}
                        />
                    </div>
                    {/* destination url */}
                    <div className="my-6 pt-3">
                        <SelectList
                            optionList={destinationUrls.map((url) => {
                                let new_url: string | URL;
                                try {
                                    new_url = new URL(url)
                                } catch (err) {
                                    new_url = ""
                                }
                                return {
                                    name: new_url instanceof URL ? new_url.hostname : "",
                                    id: url,
                                    attribute_name: "destination_url__in"
                                }
                            })}
                            selectedList={filters}
                            onChangeSearchBar={onChangeDestinationUrlSearchBar}
                            onChangeFilter={onChangeDestinationUrl}
                            sectionLabel={t("Filter by Destination")}
                            onClickLoadMore={() => onLoadMoreDestinationUrls()}
                            loadMore={loadMoreDestinationUrls}
                            loading={loadingDestinationUrls}
                        />
                    </div>
                    {/* destination type (uuid of type) */}
                    <div className="my-6 pt-3">
                        <SelectList
                            optionList={destinationTypes.map((dest) => {
                                return {
                                    name: dest.name,
                                    id: dest.value,
                                    attribute_name: "destination_type__in"
                                }
                            })}
                            selectedList={filters}
                            onChangeSearchBar={onChangeDestinationTypeSearchBar}
                            onChangeFilter={onChangeDestinationType}
                            sectionLabel={t("Filter by Destination Type")}
                            loadMore={loadMoreDestinationTypes}
                            onClickLoadMore={() => onLoadMoreDestinationTypes()}
                            loading={loadingDestinationTypes}
                        />
                    </div>
                    {/* owner (uuid of related organization) */}
                    <div className="flex flex-col my-6 pt-3">
                        <SelectList
                            optionList={organizations.map((org) => {
                                return {
                                    name: org.name,
                                    id: org.id,
                                    attribute_name: "owner__in"
                                }
                            })}
                            selectedList={filters}
                            onChangeFilter={onChangeOwner}
                            onChangeSearchBar={onChangeOwnerSearchBar}
                            sectionLabel={t("Filter by Organization")}
                            loadMore={loadMoreOrganizations}
                            onClickLoadMore={() => onLoadMoreOrganizations()}
                            loading={loadingOrganizations}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    {connections.length < 1 &&
                        <div className="w-full items-center">
                            {reloadConnections ?
                                <Spinner />
                                :
                                <NoResults
                                    headerMsg={t("No Results Found")}
                                    labelMsg={t("You may need to adjust your search or filters to find what you are looking for.")}
                                />}
                        </div>
                    }
                    {connections.length >= 1 &&
                        <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                            <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                <tr>
                                    <th className="p-3 w-28 flex flex-row align-top">
                                        {t("Health")}
                                        <span className="ml-1 cursor-pointer font-normal normal-case -mb-1.5">
                                            <Tooltip
                                                position="right"
                                                description="Connections are analyzed every hour for their health." />
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Name")}
                                        <span className="cursor-pointer" onClick={() => {
                                            requestSort("name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("name")
                                        }}>
                                            {sortByColumn === "name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Provider Type")}
                                        <span className="cursor-pointer" onClick={() => {
                                            requestSort("type__name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("type__name")
                                        }}>
                                            {sortByColumn === "type__name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Destinations")}
                                    </th>
                                    <th className="p-3">
                                        {t("Organization")}
                                        <span className="cursor-pointer" onClick={() => {
                                            requestSort("owner__name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("owner__name")
                                        }}>
                                            {sortByColumn === "owner__name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {reloadConnections ?
                                <tbody className="text-sm">
                                    <tr>
                                        <td className="p-3" colSpan={6}><Spinner /></td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="text-sm  cursor-pointer">
                                    {connections.map((connection: Connection) => {
                                        const connectionStatus = getConnectionStatus(connection.status, false, t);
                                        return (
                                            <tr key={connection.id}
                                                className="bg-very-light-gray border border-gray-lines hover:bg-light-blue align-top">
                                                <td className="p-3 w-28 " onClick={() => onSelectConnection(connection.id)}>
                                                    <p className={connectionStatus.style}>{connectionStatus.text}</p>
                                                </td>
                                                <td className="p-3" onClick={() => onSelectConnection(connection.id)}>
                                                    {connection.provider.name}
                                                </td>
                                                <td className="p-3" onClick={() => onSelectConnection(connection.id)}>
                                                    {connection.provider.type.name}
                                                </td>
                                                <td className="p-3 text-blue-basic">
                                                    {connection.destinations.map((dest: Destination) => {
                                                        let dest_url: string | URL;
                                                        try {
                                                            dest_url = new URL(dest.base_url).hostname
                                                        } catch (err) {
                                                            dest_url = ""
                                                        }
                                                        return (<p key={dest.id} onClick={() => onViewDetails(dest.id)}>{dest_url}</p>)
                                                    })}
                                                </td>
                                                <td className="p-3" onClick={() => onSelectConnection(connection.id)}>
                                                    {connection.owner.name}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>}
                        </table>}
                    {totalCount > page_size && <Pagination
                        showText={true}
                        handlePageClick={onPageClicked}
                        pageCount={Math.ceil(totalCount / take)}
                        resultStart={offset + 1}
                        resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                        totalCount={totalCount}
                        initialPage={Math.floor(offset / take)}
                        take={take}
                        loading={!pageLoaded}
                        mainclassname="w-full mt-2 flex flex-row justify-between bg-very-light-gray opacity-80"
                    />}
                </div>
            </div>
        </div>
    )
};

export default ConnectionsList;