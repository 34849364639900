import React from 'react';
import { Tooltip } from '../Tooltip';

interface CustomCheckboxProps {
    schema?: any;
    value: any;
    label: string;
    checked: boolean;
    itemsData: any;
    enumItem: any;
    onChange: (updatedItems: boolean | any[]) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
    schema,
    value,
    label,
    checked,
    itemsData,
    enumItem,
    onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        const updatedItems = Array.isArray(itemsData)
            ? (checked
                ? [...itemsData, enumItem]
                : itemsData.filter((item: any) => item !== enumItem))
            : checked;
        onChange(updatedItems);
    };
    return (
        <div className='visible flex flex-row items-baseline'>
            <input
                type="checkbox"
                className="accent-dark-green cursor-pointer"
                checked={schema ? value : checked}
                onChange={handleChange}
            />
            <label key={value} className="visible flex flex-row ml-2 mr-1 text-secondary-gray">
                {schema ? schema.title : label}
            </label>
            <label key={Math.random()} className="flex flex-row text-secondary-gray">
                {schema && schema.description && <Tooltip position="right" description={schema.description} />}
            </label>
        </div>
    );
};

export default CustomCheckbox;