import React, { useEffect, useState } from "react";
import { useKeycloakToken } from '../common/useKeycloak';
import { useNavigate } from 'react-router-dom';
import { ProviderType, buildProviderType } from "../common/ObjectTypes";
import Spinner from "../common/Spinner";
import { page_size } from "../../api/apiUtils";
import loadProviderTypes from '../../api/connections/loadProviderTypesNewConnection';
import { useTranslation } from "react-i18next";
import NewConnectionStepOne from "./NewConnectionStepOne";
import { useKeycloak } from "@react-keycloak/web";

export function ManageAddConnectionStepOne() {
    type FilterTypes = {
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    function createFilterTypes(
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    ): FilterTypes {
        return {
            endpoint__in: endpoint__in || [],
            enabled: enabled || [],
            type__in: type__in || [],
            owner__in: owner__in || [],
            ordering: ordering || "",
            search: search || ""
        }
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const { t } = useTranslation();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { keycloak, initialized } = useKeycloak();
    const getSaveButtonClassName = (step: number, disabled: boolean): string => {
        if (step === 1) {
            return disabled
                ? "bg-gray-lines text-white"
                : "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 2) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 3) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        return "";
    };
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>(createFilterTypes([], [], [], [], "", ""));

    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [reloadProviderTypes, setReloadProviderTypes] = useState(true);
    const [providerTypesOffset, setProviderTypesOffset] = useState<number>(0);
    const [providerTypesTotal, setProviderTypesTotal] = useState<number>(0);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [orgOffset, setOrgOffset] = useState<number>(0);

    const [selectedProviderType, setSelectedProviderType] = useState<ProviderType>(buildProviderType());
    const [isProviderTypeSelected, setIsProviderTypeSelected] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();

    const loadProviderTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            setReloadProviderTypes(true);
            const [provider_types, total_provider_types, error] = await loadProviderTypes(token, offset, search_str, providerTypes);
            if (!error) {
                setProviderTypes(provider_types);
                setProviderTypesTotal(total_provider_types);
                setReloadProviderTypes(false);
            }
            setPageLoaded(true);

        }
    }

    useEffect(() => {
        if (reloadProviderTypes) loadProviderTypesData(providerTypesOffset, "");
    }, [token, providerTypes])

    const handleSelectProviderType = (pt: ProviderType) => {
        setIsProviderTypeSelected(true);
        setSelectedProviderType(pt);
    }

    const handleChangeProviderTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadProviderTypesData(providerTypesOffset, e.target.value);
        }
    }

    const handleProviderTypePageClick = (e: any) => {
        setProviderTypesOffset(e.selected * page_size);
        loadProviderTypesData(e.selected * page_size, "");
    }

    const handleClickNext = () => {
        navigate(`/addconnection/configureprovider/${selectedProviderType.value}`)
    }

    const handleCancel = () => {
        navigate("/")
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                <div className="bg-white shadow static inset-x-0 px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <div className="flex flex-col">
                        {currentStep === 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("Create a Connection")}</h1>
                        </div>}
                        {currentStep > 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("New")} {selectedProviderType.name} {t("Connection")}</h1>
                            {/* tabs for provider and destinations */}
                            <div className="w-full flex flex-row font-semibold text-medium-gray">
                                <label className={currentStep === 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Provider")}
                                </label>
                                <label className={currentStep > 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Destinations")}
                                </label>
                            </div>
                        </div>}
                    </div>

                </div>
                <div className="px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <NewConnectionStepOne
                        providerTypes={providerTypes}
                        reloadProviderTypes={reloadProviderTypes}
                        onSelectProviderType={handleSelectProviderType}
                        onSearchProviderType={handleChangeProviderTypeSearchBar}
                        onPageClickProviderTypes={handleProviderTypePageClick}
                        providerTypesTotal={providerTypesTotal}
                        providerTypesOffset={providerTypesOffset}
                        pageLoaded={pageLoaded}
                    />
                </div>

                <div className="fixed flex flex-row bottom-0 right-0 pr-5 py-3 pl-16 bg-white/10 backdrop-blur w-full shadow-lg">
                    <button className={`px-8 py-2 font-bold rounded mr-3 ${getSaveButtonClassName(currentStep, !isProviderTypeSelected)}`}
                        onClick={() => handleClickNext()}
                        disabled={!isProviderTypeSelected}
                    >
                        {currentStep === 3 ? t("Finish") : t("Next")}
                    </button>
                    <button className="text-secondary-gray font-bold rounded py-2 px-3 mx-2"
                        type="button"
                        onClick={handleCancel}
                    >
                        {t("Cancel")}
                    </button>
                </div>
            </div>
        )
}