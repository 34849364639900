import React, { useState, useEffect, ChangeEvent, ChangeEventHandler } from "react";
import { useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import OrganizationModal from "./OrganizationModal";
import { toast } from 'react-toastify';
import { Organization, buildOrganization } from "../common/ObjectTypes";
import { addOrganization, updateOrganization } from "../../api/organizations/organizationsApi";
import { or } from "ajv/dist/compile/codegen";
import { useTranslation } from "react-i18next";

type ManageOrganizationModalProps = {
    onToggleModal: Function
    existingOrg?: Organization,
    newOrg: boolean
}

export function ManageOrganizationModal({
    onToggleModal,
    existingOrg,
    newOrg
}: ManageOrganizationModalProps) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const { org_id } = useParams();
    const toggleModalMouseEvent: React.MouseEventHandler<HTMLButtonElement> = () => onToggleModal(); // function passed from parent needs to be a mouse event
    const [organizaton, setOrganization] = useState<Organization>(existingOrg ? existingOrg : () => buildOrganization())
    const [errors, setErrors] = useState({});

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const item = event.target
        const item_id: string = item.id
        setOrganization((prevOrg: Organization) => ({
            ...prevOrg,
            [item_id]: item.value,
        }))
    }

    const formIsValid = () => {
        let form_errors: any = {};
        if (organizaton.name.length === 0) form_errors.name = "Name is required.";
        if (organizaton.name.length > 30) form_errors.name = "Name cannot exceed 30 characters.";
        setErrors(form_errors);
        return Object.keys(form_errors).length === 0
    }

    async function handleAddOrganization() {
        if (!formIsValid()) return;
        else {
            if (keycloak.token) {
                await addOrganization(keycloak.token, organizaton);
                toast.success(`${t('Organization added')}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                onToggleModal();
            }
        }
    }

    async function handleUpdateOrganization() {
        if (!formIsValid()) return;
        else {
            if (keycloak.token && org_id) {
                await updateOrganization(keycloak.token, org_id, organizaton);
                toast.success(`${t('Organization updated')}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                onToggleModal();
            }
        }
    }

    return (
        <OrganizationModal
            organization={organizaton}
            onToggleModal={toggleModalMouseEvent}
            onChange={handleChange}
            onSave={newOrg ? handleAddOrganization : handleUpdateOrganization}
            newOrg={newOrg}
            errors={errors}
        />
    )
}