import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import EN from "./translations/EN.json";
import ES from "./translations/ES.json";

const resources = {
    en: {
        translation: EN
    },
    es: {
        translation: ES
    }
};

i18next
    .use(LanguageDetector) // Use the browser language detector
    .use(initReactI18next) // Bind i18next to React
    .init({
        resources,
        debug: true, // Enable debug logs
        detection: {
            order: ["localStorage", "cookie", "navigator", "htmlTag"], // Prioritize localStorage
            caches: ["localStorage"], // Cache detected language in localStorage
        },
        fallbackLng: "en", // Fallback to English if no language is detected
        interpolation: {
            escapeValue: false // React already escapes values
        }
    });

export default i18next;
